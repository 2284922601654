import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import * as endpoints from "../endpoints";
import { selectFormatedAddress } from "./address";
import { selectCart, selectTotalServicesInCart } from "./cart";
import { purge } from "./storage";
import {
  customPriceReceived,
  contractDiscountsReceived,
  contractReceived,
  opportunityReceived,
  areaPolygonsReceived,
} from "./extra";
import { selectCurrentContract } from "./contract";

const initialState = () => ({
  services: [],
  availableServices: [],
  serviceInputs: {},
  serviceInputPrices: {},
  prices: [],
  extraPrices: [],
  serviceDiscounts: [],
  contractDiscounts: [],
  extraContractDiscounts: [],
  extraServiceDiscounts: [],
  groups: [],
  paymentCalendars: [],
  isPublic: true,
});

const slice = createSlice({
  name: "offer",
  initialState: initialState(),
  reducers: {
    servicesReceived: (state, action) => {
      state.services = action.payload;
    },
    serviceInputAdded: (state, action) => {
      state.serviceInputs[action.payload.serviceId] = action.payload.input;
    },
    serviceInputPriceAdded: (state, action) => {
      state.serviceInputPrices[action.payload.serviceId] =
        action.payload.inputPrice;
    },
    serviceInputRemoved: (state, action) => {
      if (action.payload.serviceId in state.serviceInputs) {
        delete state.serviceInputs[action.payload.serviceId];
      }
    },
    availableServicesReceived: (state, action) => {
      state.availableServices = action.payload.services;

      state.availableServices = state.availableServices.concat(
        state.extraPrices
          .map((p) => p.serviceId)
          .filter((serviceId) => !state.availableServices.includes(serviceId))
      );
    },
    pricesReceived: (state, action) => {
      const serviceIds = action.payload.prices.map((price) => price.service_id);

      state.prices = state.prices.filter(
        (p) => !serviceIds.includes(p.serviceId)
      );

      state.prices = state.prices.concat(
        action.payload.prices.map((price) => ({
          serviceId: price.service_id,
          id: price.id,
          price: price.price,
        }))
      );

      state.prices = state.prices.filter(
        (p) => !state.extraPrices.map((p) => p.serviceId).includes(p.serviceId)
      );

      state.prices = state.prices.concat(
        state.extraPrices.filter(
          (p) => !state.prices.map((price) => price.id).includes(p.id)
        )
      );
    },
    extraPriceReceived: (state, action) => {
      state.extraPrices = action.payload.prices.map((price) => ({
        serviceId: price.service_id,
        id: price.id,
        price: price.price,
      }));

      state.prices = state.prices.filter(
        (p) => !state.extraPrices.map((p) => p.serviceId).includes(p.serviceId)
      );

      state.prices = state.prices.concat(
        state.extraPrices.filter(
          (p) => !state.prices.map((price) => price.id).includes(p.id)
        )
      );
    },
    extraPricesRemoved: (state, action) => {
      state.extraPrices = [];
    },
    selectedExtraPricesRemoved: (state, action) => {
      state.extraPrices = state.extraPrices.filter(
        (p) => !action.payload.includes(p.id)
      );
    },
    servicePriceRemoved: (state, action) => {
      state.prices = state.prices.filter((p) => {
        if (p.serviceId !== action.payload.serviceId) {
          return p;
        }
      });
    },
    serviceDiscountsReceived: (state, action) => {
      const serviceIds = action.payload.discounts.map(
        (payloadDiscount) => payloadDiscount.service_id
      );

      state.serviceDiscounts = state.serviceDiscounts.filter(
        (stateDiscount) => !serviceIds.includes(stateDiscount.service_id)
      );

      state.serviceDiscounts = state.serviceDiscounts.concat(
        action.payload.discounts
      );

      state.serviceDiscounts = state.serviceDiscounts.concat(
        state.extraServiceDiscounts.filter(
          (d) =>
            !state.serviceDiscounts
              .map((discount) => discount.id)
              .includes(d.id)
        )
      );
    },
    serviceDiscountsRemoved(state, action) {
      state.serviceDiscounts = state.serviceDiscounts.filter(
        (discount) => !action.payload.serviceIds.includes(discount.service_id)
      );
      state.serviceDiscounts = state.serviceDiscounts.concat(
        state.extraServiceDiscounts.filter(
          (d) =>
            !state.serviceDiscounts
              .map((discount) => discount.id)
              .includes(d.id)
        )
      );
    },
    extraServiceDiscountsReceived: (state, action) => {
      const currentExtraIds = state.extraServiceDiscounts.map(
        (discount) => discount.id
      );
      state.serviceDiscounts = state.serviceDiscounts.filter(
        (discount) => !currentExtraIds.includes(discount.id)
      );
      state.extraServiceDiscounts = action.payload.discounts;
      state.serviceDiscounts = state.serviceDiscounts.concat(
        state.extraServiceDiscounts
      );
    },
    contractDiscountsReceived: (state, action) => {
      state.contractDiscounts = action.payload.discounts.concat(
        state.extraContractDiscounts.filter(
          (d) =>
            !action.payload.discounts
              .map((discount) => discount.id)
              .includes(d.id)
        )
      );
    },
    extraContractDiscountsReceived: (state, action) => {
      state.extraContractDiscounts = action.payload.discounts;

      state.contractDiscounts = action.payload.discounts.concat(
        state.extraContractDiscounts.filter(
          (d) =>
            !action.payload.discounts
              .map((discount) => discount.id)
              .includes(d.id)
        )
      );
    },
    groupsReceived: (state, action) => {
      state.groups = action.payload.groups;
    },
    paymentCalendarsReceived: (state, action) => {
      state.paymentCalendars = action.payload.calendars;
    },
    publicSet: (state, action) => {
      state.isPublic = action.payload.isPublic;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, (state) => initialState());
    builder.addCase(opportunityReceived, (state, action) => {
      // const qtyIds = state.services
      //   .filter((s) => s.type === "qty")
      //   .map((s) => s.id);

      action.payload.services
        //.filter((s) => qtyIds.includes(parseInt(s.id)))
        .forEach((service) => {
          const parsed = Number.parseInt(service.priceInput);
          if (!Number.isNaN(parsed)) {
            state.serviceInputs[service.id] = parsed;
          }
        });

      const inputIds = state.services
        .filter((s) => s.type === "input")
        .map((s) => s.id);

      action.payload.services
        .filter((s) => inputIds.includes(parseInt(s.id)))
        .forEach((service) => {
          state.serviceInputs[service.id] = service.priceInput;
        });
    });
    builder.addCase(contractReceived, (state, action) => {
      const qtyIds = state.services
        .filter((s) => s.type === "qty")
        .map((s) => s.id);

      action.payload.services
        .filter((s) => qtyIds.includes(parseInt(s.id)))
        .forEach((service) => {
          state.serviceInputs[service.id] = service.priceInput;
        });

      const inputIds = state.services
        .filter((s) => s.type === "input")
        .map((s) => s.id);

      action.payload.services
        .filter((s) => inputIds.includes(parseInt(s.id)))
        .forEach((service) => {
          state.serviceInputs[service.id] = service.priceInput;
        });
    });
    builder.addCase(areaPolygonsReceived, (state, action) => {
      const packageSlugs = ["cvert", "crenite", "ccurite"];
      for (const [serviceID, polygons] of Object.entries(action.payload)) {
        if (!packageSlugs.includes(polygons.serviceSlug)) {
          state.serviceInputs[serviceID] = parseInt(
            polygons.polygonArea.reduce((acc, curr) => acc + curr, 0).toFixed(0)
          );
        }
      }
    });
  },
});

export default slice.reducer;

const { actions } = slice;

// Action creators (commands)
export const loadServices = () =>
  apiCallBegan({
    endpoint: endpoints.SERVICES,
    onSuccess: actions.servicesReceived.type,
  });

export const setServiceInput = (serviceId, input) =>
  actions.serviceInputAdded({
    serviceId,
    input,
  });

export const setServiceInputPrice = (serviceId, inputPrice) =>
  actions.serviceInputPriceAdded({
    serviceId,
    inputPrice,
  });

export const removeServiceInput = (serviceId, input) =>
  actions.serviceInputRemoved({
    serviceId,
  });

export const removePrices = (serviceId) =>
  actions.servicePriceRemoved({ serviceId });

export const loadPrices = (serviceIds) => (dispatch, getState) => {
  const servicesAndInputs = getServicesAndInputs(getState(), serviceIds);

  dispatch(
    apiCallBegan({
      endpoint: endpoints.PRICES,
      data: {
        address: selectFormatedAddress(getState()),
        services: servicesAndInputs,
      },
      onSuccess: actions.pricesReceived.type,
    })
  );
};

export const loadExtraPriceIds = () => (dispatch, getState) => {
  const currentContract = selectCurrentContract(getState());
  const serviceInputs = selectServiceInputs(getState());
  const inputArray = Object.entries(serviceInputs).map(([key, value]) => ({
    id: parseInt(key),
    input: value,
  }));

  if (currentContract.extraPrices && currentContract.extraPrices.length > 0) {
    dispatch(
      apiCallBegan({
        endpoint: endpoints.PRICE_EXTRA_IDS,
        data: {
          extra_ids: currentContract.extraPrices,
          contract_id: currentContract.contractId,
          service_inputs: inputArray,
        },
        onSuccess: actions.extraPriceReceived.type,
      })
    );
  }
};

export const loadAllPrices = () => (dispatch, getState) => {
  const ids = selectServicesIds(getState());
  dispatch(loadPrices(ids));
};

export const removeServiceDiscounts = (serviceIds) =>
  actions.serviceDiscountsRemoved({ serviceIds });

export const loadServiceDiscounts = (servicesIds) => (dispatch, getState) => {
  const servicesAndInputs = getServicesAndInputs(getState(), servicesIds);

  dispatch(
    apiCallBegan({
      endpoint: endpoints.SERVICE_DISCOUNTS,
      data: {
        address: selectFormatedAddress(getState()),
        services: servicesAndInputs,
      },
      onSuccess: actions.serviceDiscountsReceived.type,
    })
  );
};

export const loadServiceDiscountsAndRemoveExisting =
  (servicesIds) => (dispatch, getState) => {
    dispatch(removeServiceDiscounts(servicesIds));
    dispatch(loadServiceDiscounts(servicesIds));
  };

export const loadExtraServiceDiscounts = () => (dispatch, getState) => {
  const currentContract = selectCurrentContract(getState());

  if (currentContract.contractDiscounts !== "") {
    dispatch(
      apiCallBegan({
        endpoint: endpoints.SERVICE_DISCOUNTS_EXTRA_IDS,
        data: {
          contract_id: currentContract.contractId,
          extra_ids: currentContract.serviceDiscounts,
        },
        onSuccess: actions.extraServiceDiscountsReceived.type,
      })
    );
  }
};

export const loadContractDiscounts = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      endpoint: endpoints.CONTRACT_DISCOUNTS,
      data: {
        value: selectTotalServicesInCart(getState()),
      },
      onSuccess: actions.contractDiscountsReceived.type,
    })
  );
};

export const loadAllServiceDiscounts = () => (dispatch, getState) => {
  const { services: cart } = selectCart(getState());
  dispatch(loadServiceDiscounts(cart));
};

export const loadExtraContractDiscounts = () => (dispatch, getState) => {
  const currentContract = selectCurrentContract(getState());

  if (currentContract.contractDiscounts !== "") {
    dispatch(
      apiCallBegan({
        endpoint: endpoints.CONTRACT_DISCOUNTS_EXTRA_IDS,
        data: {
          contract_id: currentContract.contractId,
          extra_ids: currentContract.contractDiscounts,
        },
        onSuccess: actions.extraContractDiscountsReceived.type,
      })
    );
  }
};

export const loadAllGroups = () =>
  apiCallBegan({
    endpoint: endpoints.GROUPS,
    onSuccess: actions.groupsReceived.type,
  });

export const loadPaymentCalendars = () =>
  apiCallBegan({
    endpoint: endpoints.PAYMENT_CALENDARS,
    onSuccess: actions.paymentCalendarsReceived.type,
  });

export const loadAvailableServices = () =>
  apiCallBegan({
    endpoint: endpoints.AVAILABLE_SERVICES,
    onSuccess: actions.availableServicesReceived.type,
  });

export const setPublic = (isPublic) => actions.publicSet({ isPublic });

export const removeSelectedOfferExtraPrices = (ids) => (dispatch, getState) => {
  const prices = selectExtraPrices(getState());

  const selectedPriceIds = prices
    .filter((p) => ids.includes(p.serviceId))
    .map((p) => p.id);

  dispatch(actions.selectedExtraPricesRemoved(selectedPriceIds));
};

export const removeOfferExtraPrices = () => actions.extraPricesRemoved();

// Selectors

export const selectExtraPrices = (state) => state.offer.extraPrices;

export const selectServices = createSelector(
  (state) => state.offer.services,
  (services) => services
);

export const selectServicesIds = createSelector(
  (state) => state.offer.services,
  (services) => services.filter((id) => id !== null).map((s) => s.id)
);

export const selectServiceNames = createSelector(
  (state) => state.offer.services,
  (services) => services.map((s) => s.slug)
);

export const selectServiceIdByName = (serviceName) =>
  createSelector(
    (state) => state.offer.services,
    (services) =>
      (services.find((s) => s.slug === serviceName) || { id: null }).id
  );

export const selectServicesIdsByName = (serviceNames) =>
  createSelector(
    (state) => state.offer.services,
    (services) =>
      services.filter((s) => serviceNames.includes(s.slug)).map((s) => s.id)
  );

export const selectServiceById = (serviceId) =>
  createSelector(
    (state) => state.offer.services,
    (services) => services.find((s) => s.id === serviceId)
  );

export const selectServicesByIds = (serviceIds) =>
  createSelector(
    (state) => state.offer.services,
    (services) => services.filter((s) => serviceIds.includes(s.id))
  );
export const selectServicePrice = (serviceId) =>
  createSelector(
    (state) => state.offer.prices,
    (prices) => {
      const priceOBj = prices.find((s) => s.serviceId === serviceId);
      return priceOBj ? priceOBj.price : null;
    }
  );

export const selectGoldenServices = createSelector(
  (state) => state.offer.services,
  (services) => services.filter((s) => s.is_golden)
);

export const selectGroupById = (groupId) =>
  createSelector(
    (state) => state.offer.groups,
    (groups) => {
      const group = groups.find((group) => group.id === groupId);
      return { id: group.id, name: group.title };
    }
  );

export const selectServiceDiscounts = (serviceId) =>
  createSelector(
    (state) => state.offer.serviceDiscounts,
    (serviceDiscounts) =>
      serviceDiscounts.filter((discount) => discount.service_id === serviceId)
  );

export const selectServiceDiscountGroupsByServiceId = (serviceId) =>
  createSelector(
    (state) => state.offer.serviceDiscounts,
    (state) => state.offer.groups,
    (serviceDiscounts, groups) =>
      groups
        .filter((group) =>
          serviceDiscounts.some(
            (discount) =>
              discount.service_id === serviceId &&
              group.id === discount.group_id
          )
        )
        .map(({ id, title }) => ({ id, name: title }))
  );

export const selectServiceGroupDiscount = (serviceId, groupId) =>
  createSelector(
    (state) => state.offer.serviceDiscounts,
    (serviceDiscounts) =>
      serviceDiscounts.filter(
        (d) => d.service_id === serviceId && d.group_id === groupId
      )
  );

export const selectServiceInputs = createSelector(
  (state) => state.offer.serviceInputs,
  (inputs) => inputs
);

export const selectServiceInputPrices = createSelector(
  (state) => state.offer.serviceInputPrices,
  (prices) => prices
);

export const selectAllServicesDiscounts = createSelector(
  (state) => state.offer.serviceDiscounts,
  (discounts) => discounts
);

export const selectContractDiscounts = createSelector(
  (state) => state.offer.contractDiscounts,
  (discounts) => discounts
);

export const selectContractGroupDiscount = (groupId) =>
  createSelector(
    (state) => state.offer.contractDiscounts,
    (discounts) => discounts.filter((d) => d.group_id === groupId)
  );

export const selectContractDiscountGroups = createSelector(
  (state) => state.offer.contractDiscounts,
  (state) => state.offer.groups,
  (discounts, groups) =>
    groups
      .filter((group) =>
        discounts?.some((discount) => group.id === discount.group_id)
      )
      .map(({ id, title }) => ({ id, name: title }))
);

export const selectContractDiscountById = (discountId) =>
  createSelector(
    (state) => state.offer.contractDiscounts,
    (contractDiscounts) => contractDiscounts.find((d) => d.id === discountId)
  );

export const selectContractDiscountGroupName = (discountId) =>
  createSelector(
    (state) => state.offer.contractDiscounts,
    (state) => state.offer.groups,
    (contractDiscounts, groups) => {
      const discount = contractDiscounts.find((d) => d.id === discountId);
      return discount
        ? groups.find((g) => g.id === discount.group_id).title
        : null;
    }
  );

export const selectCalendarById = (id) =>
  createSelector([selectAvailablePaymentCalendars], (calendars) => {
    const calendar = calendars.find((d) => d.id === id);
    return calendar ?? null;
  });

export const selectAvailablePaymentCalendars = createSelector(
  (state) => state.offer.paymentCalendars,
  (state) => state.contract.paymentType,
  (paymentCalendars, paymentType) =>
    paymentType === "card" || paymentType === "dossier"
      ? paymentCalendars.filter(
          (calendar) => calendar.available_for_new_business
        )
      : paymentCalendars
          .filter(
            (calendar) =>
              !calendar.line_items.some(
                (line) =>
                  line.payment_relative_date &&
                  line.payment_relative_date.nb_of_days === 0
              )
          )
          .filter((calendar) => calendar.available_for_new_business)
);

export const selectAvailableAmendementPaymentCalendars = createSelector(
  (state) => state.offer.paymentCalendars,
  (state) => state.contract.paymentType,
  (paymentCalendars, paymentType) =>
    paymentType === "card" || paymentType === "dossier"
      ? paymentCalendars.filter((calendar) => calendar.available_for_amendment)
      : paymentCalendars
          .filter(
            (calendar) =>
              !calendar.line_items.some(
                (line) =>
                  line.payment_relative_date &&
                  line.payment_relative_date.nb_of_days === 0
              )
          )
          .filter((calendar) => calendar.available_for_amendment)
);

export const selectServicePrices = createSelector(
  (state) => state.offer.prices,
  (prices) => prices
);

export const selectIsPublic = createSelector(
  (state) => state.offer.isPublic,
  (isPublic) => isPublic
);

export const selectAlwaysQuote = createSelector(
  (state) => selectServices(state),
  (services) => services.filter((s) => s.always_quote).map((s) => s.id)
);

export const selectPackages = createSelector(
  (state) => selectServices(state),
  (services) =>
    services
      .filter((s) => ["crenite", "cvert", "ccurite"].includes(s.slug))
      .map((s) => s.id)
);

export const selectInsectsServices = createSelector(
  (state) => selectServices(state),
  (services) =>
    services
      .filter((s) =>
        ["traitement_insectes_garanti", "traitement_insectes"].includes(s.slug)
      )
      .map((s) => s.id)
);

export const selectAvailableServicesIds = createSelector(
  (state) => state.offer.availableServices,
  (services) => services
);

export const selectServiceIdsBySection = (section) =>
  createSelector(
    (state) => state.offer.services,
    (services) => {
      if (section === "other") {
        return services
          .filter(
            (s) =>
              (s.type === "qty" ||
                s.type === "input" ||
                s.type === "custom_price") &&
              !s.slug.includes("insecte") &&
              !s.slug.includes("fertilisation") &&
              s.slug !== "sac_de_semences"
          )
          .map((s) => s.id);
      } else if (section === "lawn") {
        return services
          .filter((s) => s.type === "area" && s.is_bundle === false)
          .map((s) => s.id);
      } else if (section === "lawn_input") {
        return services
          .filter(
            (s) =>
              s.slug.includes("fertilisation") || s.slug === "sac_de_semences"
          )
          .map((s) => s.id);
      }
    }
  );

// Helper functions
const getServicesAndInputs = (state, serviceIds) =>
  serviceIds
    .filter((id) => id !== null)
    .map((id) => {
      const inputs = state.offer.serviceInputs;
      const inputPrices = state.offer.serviceInputPrices;
      return {
        id,
        ...(id in inputs && {
          input: inputs[id],
          price: inputPrices.hasOwnProperty(id) ? inputPrices[id] : null,
        }),
      };
    });
